<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="title"
        :mask-closable="false"
        @on-visible-change="handleVisibleChagne"
    >
        <Form class="formValidate" ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem v-if="type==='add' && sub" label="上级分组" prop="t" key="t">
                <p >{{ formValidate.parent_dept_name }}</p>
            </FormItem>
            <FormItem
                v-if="!sub && !disabled"
                label="上级分组"
                prop="department_id"
                key="department_id"
            >
                <Select ref="department" v-model="formValidate.department_id" transfer clearable @on-change="handleClear">
                    <Option style="display: none;" :value="departmentInfo.department_id">{{ departmentInfo.title }}</Option>
                    <Tree :data="data" @on-select-change="handleTree($event, 'department')" ></Tree>
                </Select>
            </FormItem>
            <FormItem label="分组名称" prop="name">
                <Input v-model.trim="formValidate.name" placeholder="请输入"></Input>
            </FormItem>
            <FormItem label="排序" prop="order">
                <InputNumber v-model="formValidate.order" :min="0" placeholder="请输入"></InputNumber>
            </FormItem>
            <FormItem label="备注" prop="note">
                <Input v-model.trim="formValidate.note" placeholder="请输入"></Input>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    import { tree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [tree],
        components: {},
        props: {
            show: {
                default: false
            },
            type: {
                default: 'add'
            },
            sub: {
                default: false
            },
            editInfo: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    department_id: null,
                    parent_dept_name: '',
                    name: '',
                    order: 1,
                    note: ''
                },
                ruleValidate: {
                    name: [
                        {
                            required: true, type: 'string', message: '请输入', trigger: 'blur' 
                        },
                        {
                            type: 'string', max: 32, message: '最多32个字符', trigger: 'blur'
                        }
                    ],
                    department_id: [
                        {
                            required: true, type: 'number', message: '请选择', trigger: 'blur'
                        }
                    ],
                    note: [
                        {
                            required: false, type: 'string', max: 32, message: '最多32个字符', trigger: 'blur'
                        }
                    ],
                    order: [
                        {
                            required: false, type: 'number', max: 99999, message: '最多5位数字', trigger: 'blur'
                        }
                    ]
                },
                data: [],
                departmentInfo: {
                    department_id: '',
                    title: ''
                },
                nodeData: {
                    department_id: '',
                    title: ''
                },
                title:'',
                disabled: false
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.department_tree]
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {

                    if (this.type === 'add') {
                        this.title = '新建分组'
                        if (this.sub) {
                            this.title = '新增下级'
                            this.data = this.handleRecursive(this.tree_data, this.editInfo.rowid)
                        } else {
                            this.data = this.handleRecursive(this.tree_data, '')
                        }
                    } else {
                        this.title = '编辑分组'
                        this.data = this.handleRecursive(this.tree_data, this.editInfo.parent_dept_id)
                    }
                    this.departmentInfo = {
                        department_id: this.nodeData.department_id || '',
                        title: this.nodeData.title || ''
                    }
                    if (this.type === 'edit' && this.nodeData.title === '') {
                        //隐藏上级分组选项
                        this.disabled = true
                    }

                    //新增
                    if (this.type === 'add' && !this.sub) return
                    this.formValidate = {
                        department_id: this.nodeData.department_id,
                        parent_dept_name: this.nodeData.title,
                        name: this.editInfo.name,
                        order: this.editInfo.order_index,
                        note: this.editInfo.note
                    }
                    //新增下级
                    if (this.type === 'add' && this.sub) {
                        this.formValidate.name = ''
                        this.formValidate.note = ''
                        this.formValidate.order = null
                        this.$nextTick(() => {
                            this.$refs['formValidate'].resetFields()
                        })
                    }
                }
            }
        },
        methods: {
            cancel() {
                this.formValidate = {
                    department_id: null,
                    parent_dept_name: '',
                    name: '',
                    order: 1,
                    note: ''
                }
                this.nodeData = {
                    department_id: '',
                    title: ''
                }
                this.disabled = false
                this.$refs['formValidate'].resetFields()
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let params = {
                            name: this.formValidate.name,
                            note: this.formValidate.note,
                            order_index: this.formValidate.order
                        }
                        if (this.type === 'add') {
                            if (this.sub) {
                                params.parent_dept_id = this.editInfo.rowid
                            } else {
                                params.parent_dept_id = this.formValidate.department_id
                            }
                            this.$api.post('organizations/current/departments', params).then(() => {
                                this.$emit('confirm')
                                this.cancel()
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {
                            params.parent_dept_id = this.formValidate.department_id
                            this.$api.put(`organizations/current/departments/${this.editInfo.rowid}`, params).then(() => {
                                this.$emit('confirm')
                                this.cancel()
                            }).finally(() => {
                                this.loading = false
                            })
                        }
                    }
                })
            },
            handleVisibleChagne(e) {
                if (!e) {
                    this.cancel()
                }
            },
            handleClear(e) {
                //清空数据时清除树的选中状态
                if (!e) {
                    this.data = this.handleRecursive(this.tree_data, null)
                }
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.formValidate {
    width: 60%;
}
</style>